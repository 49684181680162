
<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import HeroSection from "@/components/ui/MA/appraisals/HeroSection.vue";
import TableSection from "@/components/ui/MA/appraisals/TableSection.vue";
import {
  GETLOADING,
  GETFETCHEDSCHEDULEDAPPRAISALSGETTER,
  GETAPPRAISALPOLICIESGETTER,
  SAVEUPCOMINGPPRAISALS,
} from "@/store/modules/MA/appraisals/constants.js";

const store = useStore();

onMounted(async () => {
  const upcoming = await store.dispatch(`Appraisals/${SAVEUPCOMINGPPRAISALS}`, {
    practice: store.getters["MAgeneralModule/getCurrentPractice"],
  });
  console.log("upcoming: ", upcoming);

  upcomingAppraisals.value = upcoming?.data["upcoming-appraisals"]?.data;
});

const tableHeader = [
  { title: "Appraisal Date", prop: "date", id: "date" },
  { title: "Employee", prop: "user.profile", id: "fullname" },
  {
    title: "Preparation Form",
    prop: "preparation_form",
    id: "preparation_form",
  },
  { title: "Goals", prop: "goals", id: "goals" },
  {
    title: "Follow-up",
    prop: "follow_up_schedule",
    id: "follow_up_schedule",
  },
  {
    title: "Actions",
    prop: "user",
    id: "actions",
  },
];
const policiesTableHeader = [
  { title: "Questionnaire Name", prop: "name", id: "name" },
  { title: "Created At", prop: "created_at", id: "date" },
  { title: "Role", prop: "role.name", id: "role" },
  {
    title: "Actions",
    prop: "user",
    id: "actions",
  },
];
const loading = computed(() => store.getters[`Appraisals/${GETLOADING}`]);

const scheduledAppraisals = computed(() => {
  return store.getters[`Appraisals/${GETFETCHEDSCHEDULEDAPPRAISALSGETTER}`];
});
const AppraisalPolicies = computed(() => {
  return store.getters[`Appraisals/${GETAPPRAISALPOLICIESGETTER}`];
});
const upcomingAppraisals = ref(null);

const MainRouteName = "ma-view-appraisal";
const conductRouteName = "ma-conduct-appraisal";
</script>
<template>
  <div class="h-full w-full flex flex-col">
    <div class="p-5">
      <HeroSection
        :loading="loading"
        :data="upcomingAppraisals"
        :policies="AppraisalPolicies"
        :tableHeader="tableHeader"
        :policiesTableHeader="policiesTableHeader"
        :MainRouteName="conductRouteName"
      />
      <TableSection
        :loading="loading"
        :data="scheduledAppraisals"
        :tableHeader="tableHeader"
        :policies="AppraisalPolicies"
        :policiesTableHeader="policiesTableHeader"
        :MainRouteName="MainRouteName"
      />
    </div>
  </div>
</template>
