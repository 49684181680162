// to-do
<script setup>
import { defineProps } from "vue";
import { useStore } from "vuex";
import {
  GETALLAPPRAISALPOLICIES,
  DELETEAPPRAISALPOLICY,
} from "@/store/modules/MA/appraisals/constants.js";
import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";
import Swal from "sweetalert2";

const props = defineProps({
  loading: {
    type: Boolean,
    requried: true,
  },
  data: {
    type: Array,
    requried: true,
  },
  tableHeader: {
    type: Array,
    requried: true,
  },
});

const store = useStore();

store.dispatch(`Appraisals/${GETALLAPPRAISALPOLICIES}`);

const deleteAction = async (event) => {
  await store.dispatch(`Appraisals/${DELETEAPPRAISALPOLICY}`, {
    appraisal_policy: event.id,
  });
  await store.dispatch(`Appraisals/${GETALLAPPRAISALPOLICIES}`);
  Swal.fire({
    title: "Done",
    text: "Successfully Deleted Questionnaire",
    icon: "success",
  });
};

const tabledropdownOptions = [
  {
    type: "action",
    label: "Delete",
    action: deleteAction,
  },
];
</script>

<template>
  <div class="flex flex-col">
    <router-link :to="{ name: 'ma-create-appraisal-policy' }">
      <div
        class="
          flex
          items-center
          w-64
          bg-gray-200
          rounded-md
          h-64
          m-5
          justify-center
        "
      >
        <span class="material-icons mr-1 cursor-pointer"> add </span>New
        Questionnaire
      </div>
    </router-link>
  </div>
  <div
    class="w-11/12 mx-auto text-center text-grey my-2 flex flex-col flex-grow"
  >
    <template v-if="!props.loading">
      <el-table
        header-row-class-name="bg-gray-200"
        header-cell-class-name="text-teal py-5"
        :cell-style="{ padding: '10px' }"
        row-class-name="border-t"
        class="w-full rounded-xl overflow-hidden bg-gray-100 flex flex-grow"
        :data="props.data"
        style="width: 100%"
      >
        <el-table-column
          v-for="item in props.tableHeader"
          :key="item.index"
          :prop="item.prop"
          :label="item.title"
        >
          <template #default="scope">
            <div class="" v-if="item.id === 'date'">
              {{ new Date(scope.row.created_at).toLocaleDateString("en-GB") }}
            </div>
            <div class="" v-if="item.id === 'actions'">
              <table-options-dropdown
                :id="scope.row.id"
                :options="tabledropdownOptions"
                :dynamicParam="scope.row.id"
              >
                <span class="material-icons"> more_horiz </span>
              </table-options-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <table-skeleton />
    </template>
  </div>
</template>