<script setup>
import { onMounted, ref, computed, defineProps } from "vue";
import { useStore } from "vuex";

import {
  GETALLSCHEDULEDAPPRAISALACTION,
  GETFETCHEDSCHEDULEDAPPRAISALSGETTER,
  SAVEOVERDUEAPPRAISALS,
  SAVEPENDINGAPPRAISALS,
  GETPENDINGAPPRAISALSGETTER,
  SAVERESCHEDULEDPPRAISALS,
  GETRESCHEDULEDAPPRAISALSGETTER,
  // SAVEUPCOMINGPPRAISALS,
} from "@/store/modules/MA/appraisals/constants.js";
import Tabs from "@/components/ui/baseComponents/tabs.vue";
import TableData from "./TableData.vue";
import PolicyManager from "./PolicyManager.vue";

const overdueAppraisals = ref(null);
const props = defineProps({
  loading: {
    type: Boolean,
    requried: true,
  },
  tableHeader: {
    type: Array,
    required: true,
  },
  policies: {
    type: Array,
    requried: true,
  },
  policiesTableHeader: {
    type: Array,
    requried: true,
  },
  MainRouteName: {
    type: String,
    required: true,
  },
});

const store = useStore();

onMounted(async () => {
  await store.dispatch(`Appraisals/${GETALLSCHEDULEDAPPRAISALACTION}`, {
    practice: store.getters["MAgeneralModule/getCurrentPractice"],
  });
  // await store.dispatch(`Appraisals/${SAVEUPCOMINGPPRAISALS}`, {
  //   practice: store.getters["MAgeneralModule/getCurrentPractice"],
  // });
  await store.dispatch(`Appraisals/${SAVERESCHEDULEDPPRAISALS}`, {
    practice: store.getters["MAgeneralModule/getCurrentPractice"],
  });
  const overdue = await store.dispatch(`Appraisals/${SAVEOVERDUEAPPRAISALS}`, {
    practice: store.getters["MAgeneralModule/getCurrentPractice"],
  });
  console.log("overdue:", overdue);
  overdueAppraisals.value = overdue.data["overdue-appraisals"].data;
  await store.dispatch(`Appraisals/${SAVEPENDINGAPPRAISALS}`, {
    practice: store.getters["MAgeneralModule/getCurrentPractice"],
    is_completed: 0,
  });
});

const scheduledAppraisals = computed(() => {
  return store.getters[`Appraisals/${GETFETCHEDSCHEDULEDAPPRAISALSGETTER}`];
});

const pendingAppraisals = computed(() => {
  return store.getters[`Appraisals/${GETPENDINGAPPRAISALSGETTER}`];
});

const rescheduledAppraisals = computed(() => {
  return store.getters[`Appraisals/${GETRESCHEDULEDAPPRAISALSGETTER}`];
});

const tabledropdownOptions = [
  {
    type: "link",
    linkName: props.MainRouteName,
    paramName: "id",
    label: "View",
  },
];

const tabs = [
  {
    title: "All Appraisals",
    text: "Employee Appraisal Records",
    type: "component",
    component: () => (
      <TableData
        loading={props.loading}
        dropdownOptions={tabledropdownOptions}
        tableData={scheduledAppraisals.value || []}
        tableHeader={props.tableHeader}
      />
    ),
  },
  {
    title: "Overdue",
    text: "Overdue Appraisal Records",
    type: "component",
    component: () => (
      <TableData
        loading={props.loading}
        dropdownOptions={tabledropdownOptions}
        tableData={overdueAppraisals.value || []}
        tableHeader={props.tableHeader}
      />
    ),
  },
  {
    title: "Pending",
    text: "Pending Appraisals Records",
    type: "component",
    component: () => (
      <TableData
        loading={props.loading}
        dropdownOptions={tabledropdownOptions}
        tableData={pendingAppraisals.value || []}
        tableHeader={props.tableHeader}
      />
    ),
  },
  {
    title: "Rescheduled",
    text: "Rescheduled Appraisals Records",
    type: "component",
    component: () => (
      <TableData
        loading={props.loading}
        dropdownOptions={tabledropdownOptions}
        tableData={rescheduledAppraisals.value || []}
        tableHeader={props.tableHeader}
      />
    ),
  },
  {
    title: "Appraisal Questionnaires",
    text: "All Appraisal Questionnaires",
    type: "component",
    component: () => (
      <PolicyManager
        loading={props.loading}
        data={props.policies || []}
        tableHeader={props.policiesTableHeader}
      />
    ),
  },
];
</script>

<template>
  <div
    class="w-11/12 mx-auto text-center text-grey my-2 flex flex-col flex-grow"
  >
    <Tabs :tabs="tabs" orientation="horizontal" :borderd="true" />
  </div>
</template>